<template>
  <div>
    <standbyDialog :localLoadingDialog="localLoadingDialog"></standbyDialog>

    <primaryButton
        class="mr-2"
        :attributesArray="functionElement['attributesArray']"
        type="button"
        :value="functionElement['functionName']"
        @click="restart"
        :label="functionElement['functionName']"
        color="save"
        :disabled="loading"
        :loading="loading"
        icon="mdi-move-resize-variant"
        :shrink="functionElement['shrink']"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex"
import primaryButton from '@/commonComponents/primaryButton.vue';
import standbyDialog from '@/commonComponents/standbyDialog.vue';
import authService from '@/js/services/AuthService';
import requests from '@/js/requests';

export default {
  props: ['isValid', 'loading', 'functionElement', 'result'],

  components: {
    primaryButton,
    standbyDialog,
  },

  data() {
    return {
      localLoadingDialog: false,
    };
  },

  methods: {
    ...mapActions(["fetchUserData"]),

    restart() {
      this.localLoadingDialog = true;

      let project = this.result.valueLine.projects;
      let organisations = this.result.valueLine.organisations;

      const options = {
        function: 'switchProjectAjax',
        requestType: 'ajax',
        project: project,
        organisations: organisations,
      };
      const sentURI = 'serve.php?f=administration&f2=projectAdministration';

      return requests.frameworkAxiosRequest({
        method: 'post',
        url: sentURI,
        data: options,
      }).then((response) => {
        authService.switchContext(parseInt(project), parseInt(organisations), (jwt) => {
          this.projectSwitch = false;
          requests.frameworkPostRequest(
              '?f=global&f2=login',
              'saveAccessToken',
              {accessToken: jwt.access_token},
              async () => {
                  if (typeof response.data.result["redirect"] !== "undefined") {
                    window.location.href = response.data.result["redirect"];
                  } else {
                    location.reload();
                  }
              });
        });
      }).catch((response) => {
        console.log(response);
      });
    },
  },
};
</script>
